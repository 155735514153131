<template>
  <el-drawer
    :title="`${formatText(action)} Suggestion`"
    :visible.sync="setShow"
    direction="rtl"
    append-to-body
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <!-- Field: Originator [Customer or Humaan] -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Is this suggestion from a Customer or Humaan?"
                prop="originatorType"
                :rules="validateField()"
              >
                <el-radio-group v-model="form.originatorType">
                  <el-radio :label="ORIGINATOR_TYPE_CUSTOMER"
                    >Customer</el-radio
                  >
                  <el-radio :label="ORIGINATOR_TYPE_HUMAAN">Humaan</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            v-if="form.originatorType === ORIGINATOR_TYPE_CUSTOMER"
            type="flex"
          >
            <el-col :span="24">
              <el-form-item
                label="Name of customer"
                prop="originator"
                :rules="validateField()"
              >
                <eden-customers-select
                  :customer.sync="form.originator"
                  :multiple="false"
                  :placeholder="'Search and select the customer'"
                  return-object
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-else type="flex">
            <el-col :span="24">
              <el-form-item
                label="Name of Humaan"
                prop="originator"
                :rules="validateField()"
              >
                <eden-personnel-select
                  :personnel-type="'member'"
                  :personnel.sync="form.originator"
                  :multiple="false"
                  :placeholder="'Select the humaan'"
                  returnObject
                />
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Field: Title -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Suggestion title"
                prop="title"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.title"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Field: Suggestion Description -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Suggestion description"
                prop="description"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.description"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Field: Attachments -->
          <el-row type="flex">
            <el-col :span="24">
              <eden-attachments-upload :attachments.sync="form.attachments" />
            </el-col>
          </el-row>

          <!-- Field: Department -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="What department is concerned?"
                prop="department"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.department"
                  value-key="id"
                  placeholder=""
                >
                  <el-option
                    v-for="department in departments"
                    :key="department.id"
                    :label="department.name"
                    :value="department"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Field: Resolvers -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Assign team members(s)"
                prop="teamMembers"
                :rules="validateField()"
              >
                <eden-personnel-select
                  :personnel-type="'member'"
                  :personnels.sync="form.teamMembers"
                  :multiple="true"
                  :placeholder="''"
                  returnObject
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-center">
      <el-button type="primary" :loading="adding" @click="handleAction">{{
        `${formatText(action)} Suggestion`
      }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { setDepartments } from "@/components/Feedback/feedback.config";
import suggestions from "@/requests/feedback/suggestions";
import { mapCreateSuggestionLogDataForBackend } from "@/helpers/api-mappers/suggestion";
// import { mapSuggestionLogDataFromBackend } from "../../../helpers/api-mappers/suggestion";

const ORIGINATOR_TYPE_HUMAAN = "humaan";
const ORIGINATOR_TYPE_CUSTOMER = "customer";

export default {
  name: "SuggestionForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    suggestion: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ORIGINATOR_TYPE_HUMAAN,
      ORIGINATOR_TYPE_CUSTOMER,
      adding: false,
      form: {
        originatorType: ORIGINATOR_TYPE_HUMAAN,
        originator: {
          name: "",
          id: "",
        },
        title: "",
        status: "pending",
        description: "",
        attachments: [],
        department: null,
        teamMembers: [],
      },
      departments: setDepartments(),
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  mounted() {
    if (this.suggestion && Object.keys(this.suggestion).length > 0) {
      this.matchForm(this.suggestion);
    }
    this.$watch("form.originatorType", (oldVal, newVal) => {
      if (oldVal !== newVal) {
        this.form.originator = {
          name: "",
          id: null,
        };
      }
    });
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
    handleAction() {
      if (this.action === "add") this.add();
      else if (this.action === "edit") this.edit();
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;

        const { logged_in_userId } = this.$store.getters.user;
        const payload = mapCreateSuggestionLogDataForBackend({
          ...this.form,
          actor_id: logged_in_userId,
        });

        suggestions
          .add(payload)
          .then((response) => {
            const { status, data } = response.data;

            if (status && data && Object.keys(data).length) {
              this.$emit("success");
              this.closeEvent();
            }
            this.adding = false;
            this.$message.success(response.data.message);
          })
          .catch((error) => {
            this.adding = false;
            this.$message.error(error.response.data.message);
          });
      });
    },
    edit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const payload = mapCreateSuggestionLogDataForBackend({
          ...this.form,
        });

        suggestions
          .update({ ...payload, suggestion_id: this.suggestion.id })
          .then((response) => {
            const { status } = response.data;

            if (status) {
              this.$emit("success");
              this.closeEvent();
            }
            this.adding = false;
            this.$message.success(response.data.message);
          })
          .catch((error) => {
            this.adding = false;
            this.$message.error(error.response.data.message);
          });
      });
    },
    matchForm(payload) {
      console.log("raa", payload.suggester);
      this.form = {
        ...payload,
        originator: payload.suggester,
        teamMembers: payload.team_members,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.specific-section {
  &--header {
    padding: 8px 12px;
    background: var(--eden-grey-septenary);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 15px;

    p {
      font-weight: 500;
      font-size: 0.875rem;
    }

    i {
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  &--content {
    opacity: 1;
    transition: opacity 0.25s ease-in;
  }

  &.close {
    .specific-section--content {
      opacity: 0 !important;
      transition: opacity 0.25s ease-in;
    }
  }
}
</style>
