export const setDepartments = () => {
  let departments = [
    {
      name: "Food Production Team",
      roles: ["fpt"],
      tag: "fpt",
    },
    {
      name: "Supply (Laundry)",
      tag: "supply",
      roles: [],
    },
    {
      name: "Supply (Logistics)",
      tag: "supply",
      roles: [],
    },

    {
      name: "Supply (Cleaning)",
      tag: "supply",
      roles: [],
    },
    {
      name: "Beauty",
      tag: "beauty",
      roles: [],
    },
    {
      name: "Brand",
      tag: "brand",
      roles: [],
    },
    {
      name: "OFA",
      tag: "ofa",
      roles: ["ofa"],
    },
    {
      name: "Product",
      tag: "product",
      roles: ["growth"],
    },
    {
      name: "Engineering",
      tag: "engineering",
      roles: ["superadmin"],
    },
    {
      name: "Supply Ops",
      tag: "supply",
      roles: [],
    },
    {
      name: "Supply (KMD)",
      tag: "supply",
      roles: [],
    },
    {
      name: "Finance",
      tag: "finance",
      roles: [],
    },
  ];

  return departments.map((department, index) => {
    return {
      id: index + 1,
      name: department.name,
      tag: department.tag,
      roles: department.roles,
    };
  });
};
